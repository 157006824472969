const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  customerId: (state) => state.user.customerId,
  customerName: (state) => state.user.customerName,
  introduction: (state) => state.user.introduction,
  roles: (state) => state.user.roles,
  permissions: (state) => state.user.permissions,
  userType: (state) => state.user.userType,
  permission_routes: (state) => state.permission.routes,
  cancelTokens: (state) => state.cancelFun.cancelTokens,
};
export default getters;
