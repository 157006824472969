/**
 * @file login.js
 */
import request from "@/utils/request";

// 登录方法
export function login(username, password, code, uuid) {
    const data = {
        username,
        password,
        code,
        uuid,
    };
    return request({
        url: "/login",
        method: "post",
        params: data,
    });
}

// 获取用户详细信息
export function getInfo() {
    return request({
        url: "/get/manager/info",
        method: "get",
    });
}

// 退出方法
export function logout() {
    return request({
        url: "/logout",
        method: "post",
    });
}

// 获取验证码
export function getCodeImg() {
    return request({
        url: "/captcha/image",
        method: "get",
    });
}

// 判断是不是超管账号接口
export function loginInfo(params) {
    return request({
        url: "/get/manager/login/info",
        method: "get",
        params: params,
    });
}

// 获取短信验证码
export function smsSend(params) {
    return request({
        url: "/send/login/sms/code",
        method: "post",
        params: params,
    });
}

// 短信验证码登录
export function superManagementLogin(params) {
    return request({
        url: "/common/super/management/login",
        method: "post",
        params: params,
    });
}
// 超管登录方法
export function adminLogin(data) {
    return request({
        url: "/sms/code/login",
        method: "post",
        data: data,
    });
}

// 检测手机号账号是否匹配
export function checkUsernamePhonenonLogin(data) {
    return request({
        url: "/system/login/check/username/phoneno",
        method: "post",
        params: data,
    });
}

// 首次登录修改密码
export function firstModifyPassword(data) {
    return request({
        url: "/system/user/modify/password/new",
        method: "put",
        data: data,
    });
}

// 新登录方法
export function systemLogin(params) {
    return request({
        url: "/system/login",
        method: "post",
        params: params,
    });
}

// 首次登录修改密码验证码校验
export function systemLoginCheckCode(data) {
    return request({
        url: "/system/login/check/code/new",
        method: "post",
        params: data,
    });
}

// 首次登录修改密码-发送验证码
export function systemLoginSendCode(data) {
    return request({
        url: "/system/login/send/code/new",
        method: "post",
        data: data,
    });
}

// 超管登录
export function SystemLoginAdmin(data) {
    return request({
        url: "/system/login/admin",
        method: "post",
        data: data,
    });
}

// 获取rsa公钥
export function getRsaPublicKey() {
    return request({
        url: "/rsa/public/key/get",
        method: "get",
    });
}

// 判断登陆账号是否触发验证码机制
export function captchaMechanismTrigger(userName) {
    return request({
        url: "/system/login/captcha/mechanism/trigger?userName=" + userName,
        method: "get",
    });
}

// 生成二维码前调用
export function scanUuid() {
    return request({
        url: "/business/scan/uuid",
        method: "get",
    });
}

// 轮询扫码结果
export function scanStatus(uuid) {
    return request({
        url: "/business/scan/status",
        method: "get",
        params: { uuid: uuid },
    });
}

// 确认登录 1=取消，2=确认
export function scanConfirm(uuid) {
    return request({
        url: "/business/scan/confirm",
        method: "get",
        params: { uuid: uuid },
    });
}

// 扫码后调用
export function scanCheck(uuid) {
    return request({
        url: "/business/scan/check",
        method: "get",
        params: { uuid: uuid },
    });
}

// 微信扫码登录code换取token
export function wxGetToken(code) {
    return request({
        url: "/wx/get/token",
        method: "get",
        params: { code: code },
    });
}
