<template>
  <div class="logout">
    <div class="logout-wrap">
      <!-- <img :src="avatar" /> -->
      <el-image class="ava" :src="avatar">
        <div slot="error" class="image-slot">
          <img src="https://dobaycdn.anasit.com/cqgs_dashboard_images/avatar.png" alt="" class="ava" />
        </div>
      </el-image>
      <div class="name-wrap" @click.stop="showBtnFun">
        <div class="name">{{ name }}</div>
        <div class="icon-wrap">
          <img src="https://dobaycdn.anasit.com/cqgs_dashboard_images/logout-icon.png" alt="" class="icon" />
        </div>
      </div>

      <div class="log-btn" v-if="showBtn" @click.stop="">
        <div class="box-item">
          声音提醒
          <el-checkbox v-model="voiceChecked" @change="handleVoiceCheckChange"></el-checkbox>
        </div>
        <div class="box-item" style="border-bottom: 0.0052rem solid rgba(137, 159, 232, 0.17); padding-bottom: 0.026rem">
          实时提醒
          <el-checkbox v-model="alarmChecked" @change="handleAlarmCheckChange"></el-checkbox>
        </div>
        <div @click="loginOut" class="box-item" style="color: #e83e36">退出登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      voiceChecked: true,
      alarmChecked: true,
      showBtn: false,
    };
  },
  computed: {
    avatar() {
      let avatarUrl = Cookies.get("avatar");
      if (!avatarUrl || avatarUrl.includes("http") == -1) {
        avatarUrl = "https://dobaycdn.anasit.com/cqgs_dashboard_images/avatar.png";
      } else {
        avatarUrl = "https://dobaycdn.anasit.com" + avatarUrl;
      }
      return avatarUrl;
    },
    name() {
      return Cookies.get("userName");
    },
  },
  mounted() {
    const body = document.querySelector("body");
    body.addEventListener("click",()=>{
      this.showBtn = false;
    })
    if (localStorage.getItem("voiceChecked") != null) {
      if (localStorage.getItem("voiceChecked") == "true") {
        this.voiceChecked = true;
      } else {
        this.voiceChecked = false;
      }
    }
    if (localStorage.getItem("alarmChecked") != null) {
      if (localStorage.getItem("alarmChecked") == "true") {
        this.alarmChecked = true;
      } else {
        this.alarmChecked = false;
      }
    }
  },
  methods: {
    handleVoiceCheckChange(val) {
      console.log(val);
      localStorage.setItem("voiceChecked", val);
    },
    handleAlarmCheckChange(val) {
      console.log(val);
      localStorage.setItem("alarmChecked", val);
    },
    showBtnFun() {
      this.showBtn = !this.showBtn;
    },
    loginOut() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "botton-new-background",
      }).then(() => {
        this.$store.dispatch("LogOut").then(() => {
          location.href = "/login";
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.logout {
  position: fixed;
  right: 0.1458rem;
  top: 0.1458rem;
  z-index: 999;
  .logout-wrap {
    position: relative;
  }
  .ava {
    width: 0.1563rem;
    height: 0.1667rem;
    position: absolute;
    top: -0.0104rem;
    border-radius: 50%;
  }
  .name-wrap {
    background: linear-gradient(270deg, rgba(20, 56, 87, 0) 0%, rgba(20, 56, 87, 0.76) 54%, rgba(20, 56, 87, 0) 100%);
    padding: 0.0313rem 0.0625rem 0.0313rem 0.1146rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0.3646rem;
    cursor: pointer;
    .name {
      font-family: AlibabaPuHuiTi;
      font-size: 12px;
      font-weight: normal;
      color: #bcd2ed;
      margin-right: 0.0417rem;
      height: 0.0833rem;
      line-height: 0.0833rem;
    }
    .icon-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 0.0833rem;
      .icon {
        width: 0.0521rem;
        height: 0.0313rem;
        position: relative;
        top: 0.0104rem;
      }
    }
  }
  .log-btn {
    width: 0.8125rem;
    height: 0.6354rem;
    padding: 0.0208rem 0.0625rem;
    padding-top: 0.0521rem;
    background: linear-gradient(180deg, rgba(3, 16, 39, 0.89) 0%, #031027 100%);
    box-sizing: border-box;
    border: 0.0052rem solid #2b8bff;
    box-shadow: inset 0rem 0rem 10.0032px 0rem rgba(151, 214, 255, 0.2);
    font-family: AlibabaPuHuiTi;
    font-size: 0.0729rem;
    font-weight: normal;
    letter-spacing: 0em;
    color: #d8d8d8;
    display: flex;
    position: absolute;
    bottom: -0.2604rem;
    left: -0.4167rem;
    cursor: pointer;
    flex-flow: column;
    top: 0.1667rem;
    .box-item {
      height: 0.1667rem;
      //   margin-top: 0.022rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
