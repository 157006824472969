<template>
  <div class="chart" :ref="tip"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      widthPix: 1,
    };
  },
  props: {
    showLegend: {
      default: true,
    },
    showTitle: {
      default: true,
    },
    tip: {
      default: "task",
    },
  },
  methods: {
    initChart(series, rate = 0, title = "督导巡检") {
      let myChart = null;
      if (myChart != null && myChart != "" && myChart != undefined) {
        myChart.dispose();
      }
      var chartDom = this.$refs[this.tip];
      myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          zlevel: 0,
          text: ["{value|" + rate + "}", "{name|" + title + "}"].join("\n"),
          rich: {
            value: {
              color: "#595a61",
              fontSize: 12 * this.widthPix,
              lineHeight: 20 * this.widthPix,
              fontFamily: "DINCondensed-Bold-Bold",
              // fontWeight: "bold"
            },
            name: {
              color: "#a0a5a9",
            },
          },
          top: "center",
          left: "48%",
          textAlign: "center",
          textStyle: {
            rich: {
              value: {
                color: "#FFFFFF",
                fontSize: 18 * this.widthPix,
                fontWeight: 600,
                lineHeight: 20 * this.widthPix,
                fontFamily: "Futura LT Condensed Medium",
                lineHeight: 25 * this.widthPix,
                fontFamily: "DINCondensed-Bold-Bold",
                textAlign: "center",
              },
              name: {
                color: "#BCD2ED",
                fontSize: 12 * this.widthPix,
                fontFamily: "DINCondensed-Bold-Bold",
                // lineHeight: 20
              },
            },
          },
        },
        legend: {
          show: this.showLegend,
          bottom: "0%",
          left: "center",
          itemWidth: 4 * this.widthPix,
          itemHeight: 4 * this.widthPix,
          borderRadius: 2 * this.widthPix,
          itemGap: 15 * this.widthPix,
          textStyle: {
            color: "#BCD2ED",
          },
        },
        tooltip: {
          trigger: "item",
        },

        series: series,
      };
      myChart.clear();
      option && myChart.setOption(option);
    },
  },
  mounted() {
    this.widthPix = window.screen.width < 1920 ? 1 : Math.floor(window.screen.width / 1920);
    this.initChart();
  },
};
</script>
<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
}
</style>
