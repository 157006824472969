/**
 * @file user.js
 */
import request from "@/utils/request";
import { praseStrEmpty } from "@/utils/common";

// 判断用户是否存在缓存
export function userCheck(query) {
    return request({
        url: "/system/user/check/cache",
        method: "get",
        params: query,
    });
}
// 查询用户列表
export function listUser(query) {
    return request({
        url: "/system/user/page",
        method: "get",
        params: query,
    });
}

// 个人修改密码
export function userModifyPwd(params) {
    return request({
        url: "/system/user/modify/pwd/new",
        method: "put",
        params: params,
    });
}

// 用户关联门店
export function userShop(data) {
    return request({
        url: "/system/user/bind/shop",
        method: "post",
        data: data,
    });
}
// 用户关联检查表
export function userTable(data) {
    return request({
        url: "/system/user/bind/table",
        method: "post",
        data: data,
    });
}

// 获取用户关联门店列表（已选择）
export function getUserShop(query) {
    return request({
        url: "/business/shop/bind/by_user",
        method: "get",
        params: query,
    });
}

// 获取用户关联检查表列表（已选择）
export function getUserTable(query) {
    return request({
        url: "business/table/bind/by_user",
        method: "get",
        params: query,
    });
}
// 查询用户详细
export function getUser(userId) {
    return request({
        url: "/system/user/" + praseStrEmpty(userId),
        method: "get",
    });
}

// 新增用户
export function addUser(data) {
    return request({
        url: "/system/user",
        method: "post",
        data: data,
    });
}

// 修改用户
export function updateUser(data) {
    return request({
        url: "/system/user",
        method: "put",
        data: data,
    });
}

// 删除用户
export function delUser(userId) {
    return request({
        url: "/system/user/" + userId,
        method: "delete",
    });
}

// 导出用户
export function exportUser(query) {
    return request({
        url: "/system/user/export",
        method: "get",
        params: query,
    });
}

// 用户密码重置
export function resetUserPwd(userId, password, secretKey) {
    const data = {
        userId,
        password,
        secretKey,
    };
    return request({
        url: "/system/user/resetPwd",
        method: "put",
        data: data,
    });
}

// 用户状态修改
export function changeUserStatus(userId, status) {
    const data = {
        userId,
        status,
    };
    return request({
        url: "/system/user/changeStatus",
        method: "put",
        data: data,
    });
}

// 查询用户个人信息
export function getUserProfile() {
    return request({
        url: "/system/user/profile",
        method: "get",
    });
}

// 修改用户个人信息
export function updateUserProfile(data) {
    return request({
        url: "/system/user/profile",
        method: "put",
        data: data,
    });
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
    const data = {
        oldPassword,
        newPassword,
    };
    return request({
        url: "/system/user/profile/updatePwd",
        method: "put",
        params: data,
    });
}

// 用户头像上传
export function uploadAvatar(data) {
    return request({
        url: "/system/user/profile/avatar",
        method: "post",
        data: data,
    });
}

// 下载用户导入模板
export function importTemplate() {
    return request({
        url: "/system/user/importTemplate",
        method: "get",
    });
}
// 岗位下拉
export function postLists() {
    return request({
        url: "/system/post/lists",
        method: "get",
    });
}

//用户关联大屏-已选择
export const bindVisual = (id) =>
    request({
        url: "/visual/bind/by_customer",
        method: "get",
        params: {
            customerId: id,
        },
    });

//用户关联大屏-未选择
export const unbindVisual = (id) =>
    request({
        url: "/visual/unbind/by_customer",
        method: "get",
        params: {
            customerId: id,
        },
    });

//用户关联大屏
export const customerBindVisual = (data) =>
    request({
        url: "/system/customer/bind/visual",
        method: "post",
        data: data,
    });

// 用户权限范围内门店及节点
export const businessStructureAndShop = (data) =>
    request({
        url: "/business/structure/and/shop",
        method: "get",
        params: data,
    });

// 未选择节点 检查表
export const noSelectNode = (data) =>
    request({
        url: "/business/shop/table",
        method: "get",
        params: data,
    });
// 用户权限范围内门店及节点 检查表
export const businessShopTableChecked = (tableId) =>
    request({
        url: "/business/shop/table/checked",
        method: "get",
        params: {
            tableId: tableId,
        },
    });
// 用户权限范围内门店及节点
export const businessShopUserChecked = (userId) =>
    request({
        url: "/business/shop/user/checked",
        method: "get",
        params: {
            userId: userId,
        },
    });
// 用户权限范围内门店及节点
export const businessStructureChildren = (params) =>
    request({
        url: "/business/structure/children",
        method: "get",
        params: params,
    });
// 未选择下一级
export const noSelectNextNode = (params) =>
    request({
        url: "/business/shop/table/children",
        method: "get",
        params: params,
    });
// 用户权限范围内门店及节点-部门限制
export const structureAndShopByDept = (deptIds) =>
    request({
        url: "/business/structure/and/shop/by/dept",
        method: "get",
        params: {
            deptIds: deptIds,
        },
    });
// 用户权限范围内门店及节点
export const businessStructureAndShopSearch = (data) =>
    request({
        url: "/business/structure/and/shop/search",
        method: "get",
        params: data,
    });
// 用户权限范围内门店及节点
export const businessStructureAndShopSearchNew = (data) =>
    request({
        url: "/business/shop/table/search",
        method: "get",
        params: data,
    });
// 用户-门店关联
export const businessShopUserBind = (data) =>
    request({
        url: "/business/shop/user/bind",
        method: "post",
        data: {
            userId: data.userId,
            structureIds: data.structureIds,
            shopIds: data.shopIds,
        },
    });
